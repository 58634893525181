import React from "react";
import classNames from "classnames";

const Text = ({
  children,
  className: _className,
  variant,
  richText,
  quotes,
  tag,
}) => {
  let Tag = tag;
  let classes = "text-base";

  switch (variant) {
    case "h1":
      Tag = "h1";
      classes =
        "font-heading text-[2.25rem] sm:text-[2.75rem] font-bold md:text-[3.25rem] leading-tighter";
      break;
    case "h2":
      Tag = "h2";
      classes =
        "font-heading font-bold text-2.5xl sm:text-[2.4rem] leading-tighter";
      break;
    case "h3":
      Tag = "h3";
      classes = "font-heading font-bold text-2xl sm:text-2.5xl leading-tighter";
      break;
    case "h4":
      Tag = "h4";
      classes = "font-heading font-bold text-1.5xl sm:text-2xl leading-tighter";
      break;
    case "h5":
      Tag = "h5";
      classes = "font-heading font-bold text-1.5xl leading-tighter";
      break;
    case "h6":
      Tag = "h6";
      classes = "font-heading font-bold text-xl leading-tighter";
      break;
    case "quote":
      classes = "font-heading text-xl sm:text-2xl font-bold leading-tighter";
      break;
    case "smallQuote":
      classes = "font-heading text-lg sm:text-xl font-bold leading-tighter";
      break;
    case "xl":
      classes = "block text-xl font-medium font-body leading-normal";
      break;
    case "lg":
      classes = "block text-lg font-medium font-body leading-normal";
      break;
    case "body":
      classes = "block text-base font-body leading-normal";
      break;
    case "sm":
      classes = "block text-sm font-body leading-normal";
      break;
    case "xs":
      classes = "block text-xs font-body leading-normal";
      break;
    case "label":
      classes =
        "block text-sm font-bold leading-none font-body uppercase tracking-0.5";
      break;
    case "tag":
      classes =
        "block text-xs font-medium leading-none font-body uppercase tracking-0.5";
      break;
    case "bigTag":
      classes =
        "block text-lg font-medium leading-none font-body uppercase tracking-0.5";
      break;
    default:
      classes = "";
      break;
  }

  // Now using Tailwind's Typography plugin but since
  // Craft Redactor is usually returning string, it'll render those out.
  // Styling can still be handled with prose and no need
  // for the `richText` utility classes
  // docs: https://tailwindcss.com/docs/typography-plugin
  return React.Children.toArray(children).map((child, i) => {
    if (typeof child.type === "undefined") {
      return (
        <Tag
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          className={classNames(classes, _className, {
            quotes,
            "prose prose-sm sm:prose-base": richText,
          })}
          dangerouslySetInnerHTML={{ __html: child }}
        />
      );
    }
    // otherwise return children
    return (
      <Tag
        // eslint-disable-next-line react/no-array-index-key
        key={i}
        className={classNames(classes, _className, {
          quotes,
          "prose prose-sm sm:prose-base": richText,
        })}
      >
        {child}
      </Tag>
    );
  });
};

Text.defaultProps = {
  tag: "span",
  className: null,
  variant: null,
  richText: false,
  quotes: false,
  children: null,
  dewidow: false,
};

export default Text;
