import { useStaticQuery, graphql } from "gatsby";

const query = graphql`
  query SolutionsQuery {
    craft {
      entries(section: "solutions") {
        ... on Craft_solutions_default_Entry {
          uid
          title
          url
          slug
          descriptor: heading0
          moduleList {
            uid
          }
          icon {
            ... on Craft_icons_default_Entry {
              code: code0
            }
          }
        }
      }
    }
  }
`;

const dataResolver = ({ entries }) =>
  entries.map(s => ({ ...s, moduleList: s.moduleList.map(m => m.uid) }));

const useData = () => {
  const { craft: response } = useStaticQuery(query);
  return dataResolver(response);
};

export default useData;
