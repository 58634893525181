import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { Button, Text, Icon } from "@atoms";

const FormContainer = ({
  condensed,
  buttonText,
  children,
  className: _className,
  dark,
  errorMessage,
  formState,
  onSubmit, // ! onSubmit function must return object
  onSuccess,
  successMessage,
}) => {
  // Form States
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [form, setForm] = formState || useState({});
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [formError, setFormError] = useState([]);

  useEffect(() => {
    if (onSuccess && !!success) onSuccess();
  }, [success]);

  const requiredFields = React.Children.toArray(children)
    ?.map(_f => _f.props)
    ?.map(_f => {
      if (Array.isArray(_f?.children)) {
        return _f?.children?.map(_c => _c?.props);
      }

      return _f;
    })
    ?.flat()
    ?.filter(_f => _f?.required)
    ?.map(_f => _f?.name)
    ?.sort();

  const childrenWithProps = React.Children.map(children, child => {
    // Checking isValidElement is the safe way and avoids a typescript
    // error too.
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        formState: [form, setForm],
        errorState: [formError, setFormError],
        condensed,
        dark,
      });
    }
    return child;
  });

  const handleSubmit = async e => {
    e.preventDefault();

    const formKeys = Object.keys(form)
      ?.filter(_f => {
        const isEmpty =
          form[_f]?.length === 0 || form[_f] === false || form[_f] === null;
        return !isEmpty ? _f : false;
      })
      ?.sort();
    const hasRequiredFields =
      formKeys?.length > 0 &&
      requiredFields.every((value, index) => {
        return formKeys?.includes(value);
      });

    if (hasRequiredFields && !success) {
      setFormError([]);

      if (onSubmit) {
        try {
          const res = await onSubmit(e);

          if (!res.error) {
            setError(null);
            setSuccess(successMessage);
          } else {
            setSuccess(null);
            setError(
              res.error ||
                "Oops, Something happened with the form submission. Try again later."
            );
          }
        } catch (_e) {
          setError(errorMessage);
        }
      }
    } else {
      const missingFields = requiredFields?.filter(x => !formKeys.includes(x));
      // ?.concat(formKeys.filter(x => !requiredFields.includes(x)));

      setFormError(missingFields);
      setError(errorMessage);
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className={classNames("flex flex-col gap-5", _className)}
    >
      {!success && (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <div
          className={classNames({
            "flex gap-4": condensed,
          })}
        >
          {childrenWithProps}
          {condensed && (
            <button
              type="submit"
              className="group"
              tabIndex={0}
              aria-label="submit the form"
            >
              <Icon
                name="chevron"
                className="h-2 w-2 text-white transition duration-500 group-hover:translate-x-1"
              />
            </button>
          )}
        </div>
      )}
      {error && (
        <Text
          className={classNames("font-bold leading-normal", {
            "text-white": dark,
            "text-pink": !dark,
          })}
        >
          {error}
        </Text>
      )}
      {success && (
        <Text
          className={classNames("font-bold", {
            "text-white": dark,
            "text-black": !dark,
          })}
        >
          {success}
        </Text>
      )}
      {!condensed && (
        <div className="mt-5 flex items-center justify-center">
          <Button type="submit">{buttonText}</Button>
        </div>
      )}
    </form>
  );
};

FormContainer.defaultProps = {
  buttonText: "Submit",
  successMessage: "Thank you for your form submission.",
  errorMessage:
    "Oops, something went wrong. Please check your submission and try again.", // "Please fill out all required fields.",
};

export default FormContainer;
