import React, { useState } from "react";
import classNames from "classnames";
import { Input, FieldSet, Text } from "@atoms";
import FormsData from "@staticQueries/FormsQuery";
import { useAppState } from "@state";
import { getCookie } from "@utils/cookies";
import useSolutionsData from "@staticQueries/SolutionsQuery";
import useModulesData from "@staticQueries/ModulesQuery";
import FormContainer from "./FormContainer";

const HubspotForm = ({
  condensed,
  dark,
  descriptor,
  formId,
  heading,
  // newsletterCheckbox,
  onSuccess,
  requiredOnly,
  small,
  successMessage,
}) => {
  const [{ solution }] = useAppState();

  const solutions = useSolutionsData();
  const modules = useModulesData();

  const fSolution = solutions
    .filter(item => item.uid === solution.solution)
    .map(item => item.title);
  const fModules = modules
    .filter(item => solution.modules.includes(item.uid))
    .map(item => item.title);

  const { forms } = FormsData();
  const formFields = forms[formId];
  const [form, setForm] = useState({
    interest___solutions: fSolution,
    interest___modules: fModules,
  });

  const submitForm = async e => {
    const fields = Object.entries(form).map(([name, value]) => ({
      name,
      // checkbox group arrays need to be transformed into a
      // semi-colon-separated string.
      value: Array.isArray(value) ? value.join(";") : value,
    }));

    try {
      // Make request to submit form with updated formData
      const data = await fetch("/api/submitHubspotForm", {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          hubspotutk: getCookie("hubspotutk"),
          formId,
          pageUri: window.location.href,
          pageName: document.title,
          fields,
        }),
      });

      return data.json();
    } catch (error) {
      return error;
    }
  };

  return (
    <div className={classNames("flex flex-col justify-center")}>
      {(heading || descriptor) && (
        <div
          className={classNames("mx-auto mb-6 w-full max-w-lg text-center", {
            "text-white": dark,
            "text-black": !dark,
          })}
        >
          <Text variant={!small ? "h4" : "h5"} className="mb-4">
            {heading}
          </Text>
          <Text variant={!small ? "body" : "sm"}>{descriptor}</Text>
        </div>
      )}
      <FormContainer
        className={classNames("relative mx-auto flex flex-col justify-center", {
          "max-w-xl": !condensed,
          "w-full": condensed,
          "text-white": dark,
        })}
        formState={[form, setForm]}
        onSubmit={submitForm}
        onSuccess={onSuccess}
        successMessage={successMessage}
        condensed={condensed}
        dark={dark}
      >
        {formFields?.map((field, i) => {
          if (field.richText.content.length) {
            return (
              <div
                // eslint-disable-next-line react/no-array-index-key
                key={`richText-${i + 1}`}
                className="mt-8 mb-4 font-heading text-sm leading-normal"
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: field.richText.content }}
              />
            );
          }
          return (
            <FieldSet
              key={`hubspot-field--${i + 1}`}
              className="mb-4 flex w-full flex-wrap gap-4 md:flex-nowrap"
            >
              {field?.fields?.map((f, _i) => {
                const optionsIds = f?.options?.map(o => {
                  return { ...o, id: `${f?.name}${o?.value}` };
                });
                if (requiredOnly) {
                  if (f.required) {
                    return (
                      <Input
                        key={`${f?.name}--${_i + 1}`}
                        {...f}
                        className={classNames("w-full", {
                          "md:w-auto md:max-w-[60%]":
                            f.type === "checkbox" && field?.fields.length > 1,
                        })}
                        group={optionsIds}
                      />
                    );
                  }
                  return null;
                }
                return (
                  <Input
                    key={`${f?.name}--${_i + 1}`}
                    {...f}
                    className={classNames("w-full", {
                      "md:w-auto md:max-w-[60%]":
                        f.type === "checkbox" && field?.fields.length > 1,
                    })}
                    group={optionsIds}
                  />
                );
              })}
            </FieldSet>
          );
        })}
        {/* this will come from the form if it should be here */}
        {/* {newsletterCheckbox && (
          <Input
            label="Sign me up for your newsletter"
            name="newsletterRadio"
            type="checkbox"
            value="true"
            className="relative bottom-0 mb-3 w-full md:absolute"
          />
        )} */}
      </FormContainer>
    </div>
  );
};

HubspotForm.defaultProps = {
  color: "teal",
  dark: true,
  condensed: false,
  requiredOnly: false,
};

export default HubspotForm;
