import React, { useMemo } from "react";
import useCraftPreview from "@hooks/useCraftPreview";
import { Meta } from "@atoms";
import useBrowserTheme from "@hooks/useBrowserTheme";

export const HeadTemplate = React.memo(
  ({ data }) => {
    const { meta } = data;
    const theme = useBrowserTheme();
    return (
      <>
        <link
          id="favicon"
          rel="icon"
          type="image/png"
          sizes="16x16 32x32"
          href={
            theme === "light" ? "/images/icon.png" : "/images/icon_darkmode.png"
          }
        />
        <Meta {...meta} />
      </>
    );
  },
  () => true
);

const QueryContainer = ({
  data: _data,
  pageContext,
  location,
  template: Template,
  dataResolver,
  queryName,
}) => {
  const pageQuery = JSON.parse(process.env.GATSBY_QUERIES)[queryName];
  // get preview data if a preview
  const { data, isPreview } = useCraftPreview({
    data: _data?.craft,
    query: pageQuery,
    variables: { ...(pageContext || {}), uri: location.pathname.slice(1) },
  });
  // memoize based on whether or it is previewData (and only process data once!)
  const resolvedData = useMemo(
    () => (data ? dataResolver(data) : false),
    [isPreview, typeof data]
  );
  // return the page component if there's data
  if (resolvedData) {
    return (
      <Template
        data={resolvedData}
        location={location}
        pageContext={pageContext}
      />
    );
  }
  // show preview loading when data is pending
  if (isPreview) {
    return (
      <div className="fixed inset-0 z-[9999] flex h-full w-full flex-grow items-center justify-center bg-black text-2xl text-white">
        <div className="animate-pulse font-bold">Preview Loading...</div>
      </div>
    );
  }
  return (
    <div className="bg-red fixed inset-0 z-[9999] flex h-full w-full flex-grow flex-col items-center justify-center gap-3 text-white">
      <div className="text-2xl font-bold">Error.</div>
      <div className="text-sm">Something went wrong. Try again later.</div>
    </div>
  );
};

export default QueryContainer;
