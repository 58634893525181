import { useStaticQuery, graphql } from "gatsby";

const query = graphql`
  query FormsQuery {
    allForm {
      nodes {
        id
        formFieldGroups {
          fields {
            name
            label
            isSmartField
            type: fieldType
            hidden
            placeholder
            hideLabel: labelHidden
            required
            options {
              description
              displayOrder
              doubleData
              hidden
              label
              readOnly
              value
            }
          }
          richText {
            content
          }
        }
      }
    }
  }
`;

const dataResolver = ({ allForm }) => {
  // now reduce the forms
  const reduceForms = forms =>
    forms.reduce(
      (allForms, form) => ({
        ...allForms,
        [form.id]: form.formFieldGroups,
      }),
      {}
    );

  const forms = reduceForms(allForm.nodes);

  return {
    forms,
  };
};

const useDefaultsData = () => {
  const response = useStaticQuery(query);
  return dataResolver(response);
};

export default useDefaultsData;
