import React from "react";
import { AppLink } from "@base";
import classNames from "classnames";

const Button = ({
  color,
  to,
  title,
  type,
  onClick,
  linkObj: LinkObj,
  children,
  fullWidth,
  size,
  rounded,
  download,
}) => {
  const defaultClassName = `inline-flex rounded justify-center items-center cursor-pointer items-center relative z-0 font-heading font-bold group leading-tighter transition duration-500 focus:outline-none focus:ring focus:ring-black focus:ring-offset-2`;

  const sizes = {
    lg: "px-10 py-5 text-base",
    md: "px-5 py-3 text-sm",
    sm: "px-4 py-3 text-xs",
    xs: "px-5 py-2 text-xxs",
  };

  const colors = {
    pink: "bg-pink hover:bg-teal-dark text-white",
    blackPink: "bg-pink hover:bg-black text-white",
    teal: "bg-teal hover:bg-white text-black",
    white: "bg-white hover:bg-teal text-black",
  };

  const classes = classNames(
    defaultClassName,
    sizes[size] || "py-4 px-10 text-sm",
    colors[color] || color,
    {
      "rounded-full": rounded,
      "w-full": fullWidth,
    }
  );

  if (to && to.length > 1) {
    return (
      <LinkObj
        download={download}
        to={to}
        title={title}
        className={classes}
        onClick={onClick}
      >
        <span className="relative z-10 flex text-center">{children}</span>
      </LinkObj>
    );
  }
  return (
    <button
      // eslint-disable-next-line react/button-has-type
      type={type}
      className={classes}
      onClick={onClick}
      tabIndex={0}
    >
      <span className="relative z-10 flex text-center">{children}</span>
    </button>
  );
};

Button.defaultProps = {
  linkObj: AppLink,
  color: "pink",
  title: null,
  type: "button",
  onClick: null,
  size: "md",
  to: null,
};

export default Button;
