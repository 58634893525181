import { useStaticQuery, graphql } from "gatsby";
import resolveImage from "@dataResolvers/resolveImage";
// eslint-disable-next-line no-unused-vars
import ImageFragment from "@fragments/ImageFragment";

const query = graphql`
  query DefaultsQuery {
    craft {
      globalSet(handle: "defaults") {
        ... on Craft_defaults_GlobalSet {
          # eop cta
          ctaHeading: heading0
          ctaDescriptor: descriptor0
          ctaButton: link0 {
            text
            url
          }
          ctaLink: link1 {
            text
            url
          }
          ctaImage: image0 {
            ...ImageFragment
          }
          # newsletters signup
          newsletterHeading: heading1
          newsletterDescriptor: descriptor1
          newsletterFormId: heading2
          # meta
          metaTitle
          metaImage {
            ...ImageFragment
          }
        }
      }
    }
  }
`;

const dataResolver = ({ globalSet }) => {
  const {
    metaTitle,
    metaImage,
    ctaHeading,
    ctaDescriptor,
    ctaButton,
    ctaLink,
    ctaImage,
    newsletterHeading,
    newsletterDescriptor,
    newsletterFormId,
  } = globalSet;
  return {
    meta: {
      title: metaTitle,
      metaImage: resolveImage(metaImage),
    },
    cta: {
      heading: ctaHeading,
      descriptor: ctaDescriptor,
      button: ctaButton,
      link: ctaLink,
      image: resolveImage(ctaImage),
    },
    newsletter: {
      heading: newsletterHeading,
      descriptor: newsletterDescriptor,
      formId: newsletterFormId,
    },
  };
};

const useData = () => {
  const { craft: data } = useStaticQuery(query);
  return dataResolver(data);
};

export default useData;
