import React, { useState, useEffect } from "react";
import classNames from "classnames";
import {
  InputText,
  InputTextarea,
  InputCheckbox,
  InputRadio,
  InputSelect,
} from "@base";
import Text from "./Text";

const Input = ({
  className: _className,
  condensed,
  dark,
  errorMessage,
  errorState,
  formState,
  group,
  hideLabel,
  id,
  label,
  name,
  options,
  placeholder,
  required,
  singleLine,
  type,
  value,
}) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [form, setForm] = formState || useState({});
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [error] = errorState || useState([]);
  const hasError = error?.includes(name);
  const [checkedList, setCheckedList] = useState(form[name] || []);

  useEffect(() => {
    setCheckedList(form[name] || []);
  }, []);

  let Field;
  switch (type) {
    case "text":
      Field = InputText;
      break;
    case "textarea":
      Field = InputTextarea;
      break;
    case "checkbox":
      Field = InputCheckbox;
      break;
    case "radio":
      Field = InputRadio;
      break;
    case "select":
      Field = InputSelect;
      break;
    default:
      Field = InputText;
      break;
  }

  // Only have the group work with checkboxes and radio buttons
  // If wanting to have multiple <Input/>s, use a <FieldSet /> as a container:
  // <FieldSet>
  //   <Input>
  //   <Input>
  //   <Input>
  // </FieldSet>
  if (group?.length && (type === "checkbox" || type === "radio")) {
    return (
      <fieldset
        className={classNames(_className, {
          "text-pink": hasError,
        })}
      >
        {label && (
          <legend className={classNames("mb-2", { "sr-only": hideLabel })}>
            <span
              className={classNames(
                "font-sans block text-sm font-bold uppercase leading-none tracking-0.5",
                {
                  "text-teal": dark,
                  "text-black": !dark,
                }
              )}
            >
              {required && (
                <span
                  className={classNames({
                    "text-teal": dark,
                    "text-pink": !dark,
                  })}
                >
                  *
                </span>
              )}{" "}
              {label}
            </span>
          </legend>
        )}
        <div
          className={classNames({
            "block columns-2 gap-3": group.length > 12,
          })}
        >
          {group?.map((_g, i) => {
            return (
              <label
                htmlFor={_g?.id || name}
                key={value || _g?.id}
                className={classNames({
                  "mb-2 flex items-center": singleLine || _g?.singleLine,
                  "mb-2 inline-flex w-full cursor-pointer flex-row-reverse items-start justify-end text-xs leading-tight":
                    type === "checkbox" || type === "radio",
                })}
              >
                <span
                  className={classNames("block font-heading", {
                    "mb-2":
                      !singleLine && type !== "checkbox" && type !== "radio",
                    "ml-2": type === "checkbox" || type === "radio",
                    "mr-2": singleLine || _g?.singleLine,
                    "text-white": dark,
                    "text-black": !dark,
                  })}
                >
                  {_g?.label}
                </span>
                <Field
                  {..._g}
                  id={_g?.id || name}
                  name={_g?.name || name}
                  value={_g.value}
                  formState={[form, setForm]}
                  hasError={hasError}
                  groupState={
                    type === "checkbox" ? [checkedList, setCheckedList] : null
                  }
                  condensed={condensed}
                  dark={dark}
                  className={classNames("font-medium", {
                    "cursor-pointer": type === "checkbox",
                  })}
                  checked={checkedList.includes(_g.value)}
                />
              </label>
            );
          })}
        </div>
        {errorMessage && hasError && (
          <Text variant="xs" className="mt-1 text-pink">
            {errorMessage}
          </Text>
        )}
      </fieldset>
    );
  }

  return (
    <div className={classNames(_className)}>
      <label
        htmlFor={id || name}
        className={classNames("relative", {
          "flex items-center": singleLine,
          "flex flex-row-reverse items-center justify-end":
            type === "checkbox" || type === "radio",
          "text-pink": hasError,
        })}
      >
        <span
          className={classNames("block flex gap-1 font-heading", {
            "mb-2": !singleLine && type !== "checkbox" && type !== "radio",
            "mr-2": singleLine,
            "ml-2": type === "checkbox" || type === "radio",
            "sr-only": hideLabel,
            "text-white": dark,
          })}
        >
          {required && (
            <span
              className={classNames({
                "text-teal": dark,
                "text-pink": !dark,
              })}
            >
              *
            </span>
          )}{" "}
          {label}
        </span>
        <Field
          name={name}
          id={id || name}
          value={value}
          placeholder={placeholder}
          formState={[form, setForm]}
          hasError={hasError}
          options={options}
          condensed={condensed}
          dark={dark}
          className="font-medium"
        />
      </label>
      {errorMessage && hasError && (
        <Text variant="xs" className="mt-1 text-pink">
          {errorMessage}
        </Text>
      )}
    </div>
  );
};

Input.defaultProps = {
  dark: true,
};

export default Input;
