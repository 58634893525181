import { useStaticQuery, graphql } from "gatsby";

const query = graphql`
  query ModulesQuery {
    craft {
      entries(section: "modules") {
        ... on Craft_modules_default_Entry {
          uid
          title
          url
          descriptor: heading0
          icon {
            ... on Craft_icons_default_Entry {
              code: code0
            }
          }
          color
        }
      }
    }
  }
`;

const dataResolver = ({ entries }) => {
  return entries;
};

const useData = () => {
  const { craft: response } = useStaticQuery(query);
  return dataResolver(response);
};

export default useData;
