import React from "react";
import classNames from "classnames";
import { AppLink } from "@base";
import Icon from "./Icon";

const Wrapper = ({ children, className: _className, condition, onClick }) => {
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {condition || onClick ? (
        <AppLink
          to={condition}
          onClick={onClick}
          className={classNames(_className)}
        >
          {children}
        </AppLink>
      ) : (
        <div className={classNames(_className)}>{children}</div>
      )}
    </>
  );
};

const ButtonAlt = ({
  children,
  className: _className,
  color,
  icon,
  onClick,
  reverse,
  small,
  to,
}) => {
  const linkStyle = {
    white: "text-black decoration-teal group-hover:decoration-pink",
    lightTeal: "text-black decoration-black group-hover:decoration-pink",
    lighterTeal: "text-black decoration-black group-hover:decoration-pink",
    teal: "text-black decoration-teal-lighter group-hover:decoration-black",
    darkTeal: "text-white decoration-white group-hover:decoration-teal",
    darkerTeal: "text-white decoration-white group-hover:decoration-teal",
    black: "text-white decoration-white group-hover:decoration-teal",
    purple: "text-white decoration-white group-hover:decoration-teal-light",
    pink: "text-white decoration-white group-hover:decoration-black",
    monoTeal:
      "text-teal-light decoration-teal-light group-hover:text-white group-hover:decoration-white",
    whiteBlack:
      "text-black decoration-teal group-hover:text-white group-hover:decoration-pink",
  }[color];

  return (
    <Wrapper
      condition={to}
      onClick={onClick}
      className={classNames("group inline-flex items-center", _className)}
    >
      <span
        className={classNames(
          "inline-flex items-center gap-1 underline decoration-2 underline-offset-[.25rem] duration-500",
          linkStyle
        )}
      >
        {/* back arrow */}
        {icon && reverse && (
          <Icon
            name={icon}
            className={classNames(
              "flex-shink-0 ml-1 h-auto rotate-180 duration-500 group-hover:-translate-x-2",
              {
                "w-[0.33rem]": !small,
                "w-1": small,
              }
            )}
          />
        )}
        {/* label */}
        <span
          className={classNames("font-heading font-bold leading-normal", {
            "text-sm": !small,
            "text-xs": small,
          })}
        >
          {children}
        </span>
        {/* forward arrow */}
        {icon && !reverse && (
          <Icon
            name={icon}
            className={classNames(
              "flex-shink-0 ml-1 h-auto duration-500 group-hover:translate-x-2",
              {
                "w-[0.33rem]": !small,
                "w-1": small,
              }
            )}
          />
        )}
      </span>
    </Wrapper>
  );
};

ButtonAlt.defaultProps = {
  reverse: false,
  icon: "chevron",
  color: "white",
};

export default ButtonAlt;
