import { useState, useEffect } from "react";

const useBrowserTheme = () => {
  const [theme, setTheme] = useState("light");

  useEffect(() => {
    if (window.matchMedia(`(prefers-color-scheme: dark)`)) {
      setTheme("dark");
    }
  }, []);

  return theme;
};

export default useBrowserTheme;
